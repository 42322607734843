.MyEventsPage__container {
  margin: 0 auto;
  padding: 70px 0;
  min-height: 80vh;
  /* max-width: 800px; */
  /* align-items: center; */
  width: 100%;
  /* margin: 0; */
  /* text-align: center; */
  /* height: 100vh; */
  /* padding-top: 70px; */
  /* box-sizing: border-box; */
  /* background: rgb(203, 220, 252) url("Desktop-1.png") repeat 0 0; */
  background: rgb(203, 220, 252) url("Desktop-2.png") repeat 0 0;
  /* animation: slide 30s linear infinite; */
}

@keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -2108px 0;
  }
}

.MyEventsPage__subContainer {
  max-width: 800px;
  font-family: sans-serif;
  margin: 10px auto;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  border-radius: 7px;
  /* height: 100%; */
  align-content: stretch;
  justify-content: space-around;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
  padding: 10px;
  background-color: rgb(241, 251, 253);
}

.MyEventsPage {
  max-width: 100%;
  font-family: sans-serif;
  margin: 10px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  border-radius: 7px;
  /* height: 100%; */
  align-content: stretch;
  justify-content: space-around;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
  padding: 10px;
  background-color: rgb(241, 251, 253);
}
.MyEventsPage h2 {
  width: 95%;

  padding: 10px;
  text-align: center;
  margin: 5px auto;
  border-radius: 7px;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
  background: rgba(250, 248, 241, 0.666);
}

.MyEventsPage ul {
  padding: 0px;
  /* display: inline-block;
    margin: 5px; */
  /* background-color: red;     */
  /* display: flex; */
  /* flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    border-radius: 7px; */
  /* height: 100%; */
  /* align-content: stretch; */
  /* justify-content: space-evenly; */
  width: 95%;
  margin: auto;
}
.MyEventsPage .h3 {
  width: 95%;
  padding: 10px;
  text-align: center;
  margin: 5px auto;
  border-radius: 7px;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
  background: rgba(250, 248, 241, 0.666);
}
