.fetch-error-bar {
    font-family: sans-serif;
    margin: 10px auto;
    max-width: 800px;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    border-radius: 7px;
    /* height: 100%; */
    align-content: stretch;
    justify-content: space-around;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    transition: all 0.4s ease 0s;
    padding: 10px;
    background-image: linear-gradient(to top, #ffffffda, #f71109da, #ffffffda);
  }
  
  .fetch-error-bar {
    text-align: center;
  }
  
  .fetch-error-bar h2 {
    width: 95%;
    color: darkred;
    padding: 10px;
    text-align: center;
    margin: 0 auto;
    border-radius: 7px;
    text-shadow: 0px 0px 6px rgba(253, 253, 253, 0.2);
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    transition: all 0.4s ease 0s;
    background: rgba(250, 248, 241, 0.666);
  }
  
  .error-bar-header {
    text-align: center;
  }
  