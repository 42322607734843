.weekend__button__component {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background: #000; */
}

.weekend__button {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: rgb(0, 64, 143);
  cursor: pointer;
  position: relative;
  z-index: 0;
  font-size: large;
  border-radius: 10px;
}

.weekend__button:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.weekend__button:hover {
  color: #000;
}

.weekend__button:hover:after {
  background: transparent;
}

.weekend__button:before {
  opacity: 1;
}

.weekend__button:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(65, 0, 97, 0.906);
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}
