.footer {
  padding: 15px 0;
  text-align: center;
  height: 10vh;
  background: linear-gradient(
    to bottom,
    rgb(241, 251, 253),
    rgb(241, 251, 253),
    rgb(241, 251, 253),
    rgb(12, 19, 91)
  );
}
.footer p {
  margin-bottom: 0;
  /* padding: 0; */
}
