@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {

  text-decoration: none;

}

ul {
  list-style-type: none;
margin:0px;
padding:0px;
}

li {

  list-style: none;

}
.green{
  color: green;
}

.red{
  color: red;
}

.bold{
  font-weight: bold;
}
.alert{
  color:red;
  margin: 10px auto;
}
.Nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
}

.app-header {
  display: flex;
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  top: 0;
  height: 10vh;
  justify-content: space-between;
  padding: 10px 10px;
  /* border-bottom: 5px solid ; */
  /* border-color: rgb(54, 12, 91); */
  text-transform: uppercase;
  letter-spacing: -0.025em;
  align-items: center;
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    from(#487eb0),
    color-stop(rgb(241, 251, 253)),
    color-stop(rgb(241, 251, 253)),
    to(rgb(241, 251, 253))
  );
  background: linear-gradient(
    to bottom,
    #487eb0,
    rgb(241, 251, 253),
    rgb(241, 251, 253),
    rgb(241, 251, 253)
  );
  /* margin-bottom: 15px; */
  /* color:white; */
  /* color:purple,blue,skyblue,green,yellow,orange,red; */
  /* background: linear-gradient(to right,purple,blue,skyblue,green,yellow,orange,red); */
  z-index: 1111;
  /* max-width: 1450px; */
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .app-header {
    padding: 10px 25px;
  }
}

.login-controls,
.logged-in-controls {
  flex: 40% 1;
  text-align: right;
}

.btn {
  /* border: 2px solid #7b8083; */
  background: none;
  /* padding: 10px 20px; */
  font-size: 20px;
  font-family: "montserrat";
  cursor: pointer;
  margin: 0 3px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  position: relative;
  overflow: hidden;
}
.btn1,
.btn2 {
  color: #2b3033;
}
.btn3,
.btn4 {
  color: #ffffff;
}
.btn1:hover,
.btn2:hover {
  color: #fff;
}
.btn3:hover,
.btn4:hover {
  color: #212121;
}
.btn::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0%;
  background: rgba(84, 143, 245, 0.673);
  z-index: -1;
  -webkit-transition: 0.8s;
  transition: 0.8s;
}
.btn1::before,
.btn3::before {
  top: 0;
  border-radius: 4px;
}
.btn2::before,
.btn4::before {
  bottom: 0;
  border-radius: 4px;
}
.btn3::before,
.btn4::before {
  height: 100%;
}
.btn1:hover::before,
.btn2:hover::before {
  height: 100%;
}
.btn3:hover::before,
.btn4:hover::before {
  height: 0%;
}

.header__nav {
  /* height: 100px; */
  /* background: #34495e; */
  /* padding: 0 20px; */
  color: #fff;
  flex: 40% 1;
  text-align: right;
}
.logo {
  /* line-height: 30px; */
  float: left;
  text-transform: uppercase;
  /* width:50px; */
}
.fa-ellipsis-h {
  color: grey;
}
.menu {
  float: right;
  line-height: 40px;
  width: 80%;
  /* color:black; */
  /* border: #212121 solid ; */
  text-align: right;
  height: 100%;
}
.menu a {
  /* color: black; */
  /* text-transform: uppercase; */
  text-decoration: none;
  padding: 0 3px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.show-menu-btn,
.hide-menu-btn {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  font-size: 30px;
  cursor: pointer;
  display: none;
}
.show-menu-btn {
  float: right;
}
.show-menu-btn i {
  line-height: 80px;
}
/* .menu a:hover,
.show-menu-btn:hover,
.hide-menu-btn:hover{
  color: #3498db;
} */

#chk {
  position: absolute;
  visibility: hidden;
  z-index: -1111;
}

@media screen and (max-width: 750px) {
  .show-menu-btn,
  .hide-menu-btn {
    display: block;
  }
  .menu {
    /* display: flex; */
    position: fixed;
    width: 100%;
    /* height: 100vh; */
    background: #487eb0;
    left: -100%;
    top: 0;
    text-align: center;
    padding: 80px 0;
    line-height: normal;
    -webkit-transition: 0.7s;
    transition: 0.7s;
    z-index: 1111;
    /* display: none; */
    /* line-height: 100px; */
    -webkit-margin-before: 0;
            margin-block-start: 0;
  }
  .menu a {
    display: block;
    padding: 20px;
  }
  .hide-menu-btn {
    position: absolute;
    top: 40px;
    right: 40px;
  }
  #chk:checked ~ .menu {
    -webkit-transition: 1.5s;
    transition: 1.5s;
    left: 0;
    /* animation: menuBar 2s ease; */
  }

  @-webkit-keyframes menuBar {
    0% {
      left: 25;
    }

    /* 50% {
      left: -10;
    }
    60% {
      left: 5;
    } */
    100% {
      left: 0;
    }
  }

  @keyframes menuBar {
    0% {
      left: 25;
    }

    /* 50% {
      left: -10;
    }
    60% {
      left: 5;
    } */
    100% {
      left: 0;
    }
  }
  /* .menu a:active {
    color: blue;
  } */

  /* .menu a:active #chk {
    checked: attr(false);
  } */
}

.footer {
  padding: 15px 0;
  text-align: center;
  height: 10vh;
  background: -webkit-gradient(
    linear,
    left top, left bottom,
    from(rgb(241, 251, 253)),
    color-stop(rgb(241, 251, 253)),
    color-stop(rgb(241, 251, 253)),
    to(rgb(12, 19, 91))
  );
  background: linear-gradient(
    to bottom,
    rgb(241, 251, 253),
    rgb(241, 251, 253),
    rgb(241, 251, 253),
    rgb(12, 19, 91)
  );
}
.footer p {
  margin-bottom: 0;
  /* padding: 0; */
}

html {
    scroll-behavior: smooth;
}
.SearchPage {
  min-height: 125vh;
  width: 100%;
  margin: 0;
  padding: 70px 0;
  font-family: sans-serif;
  display: flex;
  background: url(/static/media/Desktop-1.05bea157.png) fixed center;
  background-size: fixed;
  /* animation: landingPageSlide 25s ease infinite; */
}

@-webkit-keyframes landingPageSlide {
  0% {
    background: url(/static/media/topLeft.3f2b0c40.jpg) bottom;
  }
  25% {
    background: url(/static/media/topRight.1b499c82.jpg) bottom;
  }
  50% {
    background: url(/static/media/downLeft.023c49d6.jpg) bottom;
  }
  100% {
    background: url(/static/media/downRight.9d3c7cd8.jpg) bottom;
  }
}

@keyframes landingPageSlide {
  0% {
    background: url(/static/media/topLeft.3f2b0c40.jpg) bottom;
  }
  25% {
    background: url(/static/media/topRight.1b499c82.jpg) bottom;
  }
  50% {
    background: url(/static/media/downLeft.023c49d6.jpg) bottom;
  }
  100% {
    background: url(/static/media/downRight.9d3c7cd8.jpg) bottom;
  }
}

@media (min-width: 800px) {
  .SearchPage {
    min-height: 85vh;
  }
  .SearchPage {
    min-height: 85vh;
  }
}

.Search {
  /* height: 100%; */
  /* width: 600px; */
  margin: auto;
  padding: 20px;
  display: flex;
  /* justify-content: space-around; */
  align-items: center;
  flex-direction: column;
  font-family: sans-serif;
  /* background: rgba(255, 255, 255, 0.74); */
  border-radius: 10px;
  /* background-image: linear-gradient(20deg,white,#050505a4,white); */
  background-image: radial-gradient(#487eb0da, rgb(255, 255, 255));

  /* background-repeat: no-repeat; */
  /* background-size: 1450px ;
    padding-top: 1vh;
    padding-bottom:auto;
    margin :auto; */
}

.Search h2 {
  /* max-width: 80%; */

  padding: 10px;
  text-align: center;
  margin: 0 auto;
  border-radius: 7px;
  /* text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    transition: all 0.4s ease 0s; */
  /* background: rgba(250, 248, 241, 0.281); */
  -webkit-animation: type 10s steps(22);
          animation: type 10s steps(22);
  overflow: hidden;
  white-space: nowrap;
  /* border-right: 4px solid black;
    border-left: 4px solid black; */
  max-width: 22ch;
}

.Search h3 {
  /* max-width: 80%; */

  padding: 10px;
  text-align: center;
  margin: auto;
  margin-top: 10px;
  border-radius: 7px;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  background: rgba(250, 248, 241, 0.666);
}

@-webkit-keyframes type {
  0% {
    width: 0px;
  }

  50% {
    width: 22ch;
  }
  60% {
    width: 0ch;
  }
  100% {
    width: 22ch;
  }
}

@keyframes type {
  0% {
    width: 0px;
  }

  50% {
    width: 22ch;
  }
  60% {
    width: 0ch;
  }
  100% {
    width: 22ch;
  }
}

.Search h4 {
  /* max-width: 80%; */

  padding: 10px;
  text-align: center;
  margin: 0 auto;
  border-radius: 7px 7px 0 0;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  background: rgba(250, 248, 241, 0.666);
}

.form {
  margin-bottom: 20px;
  width: 100%;
  max-width: 350px;
  /* position: relative; */
  /* height: 50px; */
  /* background: blue; */
  /* overflow:hidden; */
  align-items: center;
  border-radius: 11px;
  /* height: 80vh; */
  /* background: rgb(250, 248, 241); */
}
@media (min-width: 800px) {
  .Search {
    /* height: 85vh; */
  }
}

.form-section {
  margin: 10px auto;
  width: 100%;
  position: relative;
  height: 50px;
  border-radius: 0 0 7px 7px;
  z-index: 1;
  /* text-align: center; */
  /* background: blue; */
  /* overflow:hidden; */
}

.date input {
  border-bottom: 4px solid #487eb0;
}

.checkbox__section {
  padding: 10px auto 0px auto;
  margin: 0px !important;
  min-height: 170px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  /* height: 100%; */
  align-content: stretch;
  background: rgba(250, 248, 241, 0.666);
}

.checkbox__section input + label {
  width: 35%;
  flex-grow: 1;
}

.checkbox__section input + label {
  /* display: inline-block; */
  /* align-items: center; */
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
}

.checkbox__section input {
  display: none;
}

.checkbox__section input + label:before {
  content: "\2714";
  border: 0.1em solid #000;
  border-radius: 0.2em;
  display: inline-block;
  width: 1em;
  height: 1em;
  padding-left: 0.2em;
  padding-bottom: 0.3em;
  margin-right: 0.2em;
  vertical-align: bottom;
  color: transparent;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.checkbox__section input + label:active:before {
  -webkit-transform: scale(0);
          transform: scale(0);
}

.checkbox__section input:checked + label:before {
  background-color: rgb(97, 132, 248);
  border-color: rgb(97, 132, 248);
  color: #fff;
}

.checkbox__section input:disabled + label:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  border-color: #aaa;
}

checkbox__section input:checked:disabled + label:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  border-color: rgb(157, 199, 250);
  background-color: rgb(157, 199, 250);
}

.input {
  width: 100%;
  height: 100%;
  color: grey;
  padding-top: 10px;
  font-size: 20px;
  border: none;
  text-align: center;
  outline: none;
  border-radius: 50px;
  text-align: center;
}
.label-name {
  position: absolute;
  bottom: 0px;
  left: 0%;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-bottom: 2px solid lightcoral;
  text-align: center;
  border-radius: 50px;
}

.label-name::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -2px;
  height: 100%;
  width: 100%;
  border-bottom: 4px solid #487eb0;
  -webkit-transform: translateY(-3%);
          transform: translateY(-3%);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  border-radius: 50px;
}

.content-name {
  position: absolute;
  bottom: 5px;
  left: 0px;
  right: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.input:focus + .label-name .content-name,
.input:valid + .label-name .content-name {
  -webkit-transform: translateY(-150%);
          transform: translateY(-150%);
  font-size: 14px;
  color: #487eb0;
}

.input:focus + .label-name::after,
.input:valid + .label-name::after {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.form-btn {
  width: 60%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.searchForm__btn {
  margin: 20px;
  width: 100%;
  border: none;
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #487eb0;
  padding: 10px;
  border-radius: 50px;
  display: inline-block;
  border: none;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  font-size: large;
}

.searchForm__btn:hover {
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.CurrLocBtn {
  width: 35px;
  height: 35px;
  border: none;
  /* border-radius: 10px; */
  position: absolute;
  top: 10%;
  left: 82%;
  padding: 0;
  background-color: transparent;
}
.no-focus-CurrLocBtn:focus{
  outline: none;
}
.CurrLocBtn:hover{
  cursor: pointer;
}


.loc{
  overflow: visible;
}
.loc-mid{
  -webkit-animation: mid-pulse 2s linear 0s infinite alternate;
          animation: mid-pulse 2s linear 0s infinite alternate;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

@-webkit-keyframes mid-pulse {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
  }
}

@keyframes mid-pulse {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  to {
    -webkit-transform: scale(1.4);
            transform: scale(1.4);
  }
}

.loc-dots {
  -webkit-animation: pulse-dots 2s linear 0s infinite alternate;
          animation: pulse-dots 2s linear 0s infinite alternate;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
}

@-webkit-keyframes pulse-dots{
  from{-webkit-transform: scale(1);transform: scale(1);}
  to{-webkit-transform: scale(1.3);transform: scale(1.3);}
}

@keyframes pulse-dots{
  from{-webkit-transform: scale(1);transform: scale(1);}
  to{-webkit-transform: scale(1.3);transform: scale(1.3);}
}

.loc-arrows {
  /* animation: pulse-arrows 2s linear 0s infinite normal; */
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
@-webkit-keyframes pulse-arrows{
  from{-webkit-transform: scale(1);transform: scale(1);}
  to{-webkit-transform: scale(1);transform: scale(1);}
}
@keyframes pulse-arrows{
  from{-webkit-transform: scale(1);transform: scale(1);}
  to{-webkit-transform: scale(1);transform: scale(1);}
}
/* LOADING LOCATION */
.loc-mid-loading{
  -webkit-animation: mid 3s linear 0s infinite normal;
          animation: mid 3s linear 0s infinite normal;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  /* transform: scale(); */
}

.loc-mid-border-loading {
  -webkit-animation: mid 3s linear 0s infinite normal;
          animation: mid 3s linear 0s infinite normal;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

@-webkit-keyframes mid {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes mid {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.loc-dots-loading {
  animation: spin-dots 3s linear 0s infinite reverse;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

@-webkit-keyframes spin-dots{
  from{-webkit-transform: rotate(0deg);transform: rotate(0deg);}
  to{-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}

@keyframes spin-dots{
  from{-webkit-transform: rotate(0deg);transform: rotate(0deg);}
  to{-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}

.loc-arrows-loading {
  -webkit-animation: spin-arrows 2s linear 0s infinite normal;
          animation: spin-arrows 2s linear 0s infinite normal;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}
@-webkit-keyframes spin-arrows{
  from{-webkit-transform: rotate(0deg);transform: rotate(0deg);}
  to{-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}
@keyframes spin-arrows{
  from{-webkit-transform: rotate(0deg);transform: rotate(0deg);}
  to{-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}


/* .EventItem__Container {
    width: 95%;
    text-align: center;
    margin-left:auto;
    margin-right:auto;
    align-items: center;
    position: relative;
    min-height: 200px;
} */

.EventItem {
  position: relative;
  display: inline-block;
  padding: 15px 30px;
  /* color: #2196f3; */
  /* text-transform: uppercase; */
  /* letter-spacing: 4px; */
  text-decoration: none;
  /* font-size: 24px; */
  overflow: hidden;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  width: 100%;
  border-radius: 7px;
  /* background-color: rgba(108, 173, 233, 0.968); */
}
.EventItem:hover {
  /* color: #ffffff; */
  /* background: #2196f3; */
  box-shadow: 0 0 10px #2196f3, 0 0 40px #2196f3;
  background-color: #daeefee7;

  -webkit-transition-delay: 1s;

          transition-delay: 1s;
  border-radius: 7px;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.EventItem span {
  position: absolute;
  display: block;
}
.EventItem span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 3px;
  background: -webkit-gradient(linear, left top, right top, from(transparent), to(red));
  background: linear-gradient(90deg, transparent, red);
  box-shadow: 0 0 10px #2196f3;
}
.EventItem:hover span:nth-child(1) {
  left: 100%;
  -webkit-transition: 1s;
  transition: 1s;
}
.EventItem span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 3px;
  background: -webkit-gradient(linear, right top, left top, from(transparent), to(red));
  background: linear-gradient(270deg, transparent, red);
  box-shadow: 0 0 10px #2196f3;
}
.EventItem:hover span:nth-child(3) {
  right: 100%;
  -webkit-transition: 1s;
  transition: 1s;
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}
.EventItem span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 3px;
  height: 100%;
  background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(red));
  background: linear-gradient(180deg, transparent, red);
  box-shadow: 0 0 10px #2196f3;
}
.EventItem:hover span:nth-child(2) {
  top: 100%;
  -webkit-transition: 1s;
  transition: 1s;
  -webkit-transition-delay: 0.25s;
          transition-delay: 0.25s;
}
.EventItem span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 3px;
  height: 100%;
  background: -webkit-gradient(linear, left bottom, left top, from(transparent), to(red));
  background: linear-gradient(360deg, transparent, red);
  box-shadow: 0 0 10px #2196f3;
}
.EventItem:hover span:nth-child(4) {
  bottom: 100%;
  -webkit-transition: 1s;
  transition: 1s;
  -webkit-transition-delay: 0.75s;
          transition-delay: 0.75s;
}

.EventItems {
  display: inline;
  /* flex-direction:column ;
      justify-content: center; */
  align-items: center;
  text-align: center;
}

.EventItem__description {
  /* position: relative;
    display: inline-block;
text-decoration: none;
overflow: hidden;
transition: 0.2s;
    width: 100%;
    border-radius: 7px; */
  max-height: 120px;
  display: none;
  overflow: auto;
}
.EventItem__description p {
  /* position: absolute; */
  /* display: block; */
  /* text-align: justify; */
  /* text-align: center; */
  /* z-index: 2; */
}

.EventItem__learnMore {
  padding-top: 5px;
}

.EventItem__favorite {
  min-height: 60px;
  margin: 0px;
  padding: 0px;
  font-family: "helvetica Neue";
  position: relative;
}
.button {
  width: 200px;
  height: 50px;
  background-image: radial-gradient(#2196f3, rgb(241, 251, 253));
  position: absolute;
  left: 50%;
  bottom: -160%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 7px;
  overflow: hidden;
  cursor: pointer;
  color: white;
  font-size: large;
  font-weight: bold;
}
.button:after,
.button:before {
  content: "Favorite";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
}
.button:hover:after {
  top: -50%;
}
.button:before {
  font-family: "Ionicons";
  content: "\f141";
  font-size: 40px;
  top: 190%;
}
.button:hover:before {
  top: 50%;
}

.EventItem:hover .button {
  bottom: -20%;
  -webkit-transition: 1.5s;
  transition: 1.5s;
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}

.button:hover {
  width: 90px;
  /* transition: .05s; */
  border-radius: 70px;
}

.button:hover {
  /* transition: .05s; */
  /* color:red; */
  /* transition-delay: 1s; */
}

.button:active {
  /* transition: .05s; */
  background: radial-gradient(#ff0000, rgb(255, 255, 255));
  /* transition-delay: 1s; */
}
.button:focus .button:before {
  font-family: "Ionicons";
  content: "\f375";
  font-size: 40px;
  top: 190%;
}

.EventItem:hover .EventItem__description {
  display: block;
}

.EventItem__remove {
  min-height: 70px;
  margin: 0px;
  padding: 0px;
  font-family: "helvetica Neue";
  position: relative;
}
.EventItem__removebtn {
  width: 200px;
  height: 50px;
  background-image: radial-gradient(#ff1900, rgb(241, 251, 253));
  position: absolute;
  left: 50%;
  bottom: -20%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 7px;
  overflow: hidden;
  cursor: pointer;
  color: white;
  font-size: large;
  font-weight: bold;
}
.EventItem__removebtn:after,
.EventItem__removebtn:before {
  content: "Remove";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
}
.EventItem__removebtn:hover:after {
  top: -50%;
}
.EventItem__removebtn:before {
  font-family: "Ionicons";
  content: "\f252";
  font-size: 40px;
  top: 190%;
}
.EventItem__removebtn:hover:before {
  top: 50%;
}

.EventItem__removebtn:hover {
  width: 70px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 70px;
  /* transition-delay: 1s; */
}

.EventItem:hover .EventItem__removebtn {
  bottom: -20%;
  -webkit-transition: 1s;
  transition: 1s;
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}

.EventItem__removebtn:focus .button:before {
  font-family: "Ionicons";
  content: "\f141";
  font-size: 40px;
  top: 190%;
  background: blue;
}

.EventItem__unfavorite {
  min-height: 60px;
  margin: 0px;
  padding: 0px;
  font-family: "helvetica Neue";
  position: relative;
}

.EventItem__unfavoritebtn {
  width: 200px;
  height: 50px;
  background-image: radial-gradient(#ff1900, rgb(255, 255, 255));
  position: absolute;
  left: 50%;
  bottom: -20%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 7px;
  overflow: hidden;
  cursor: pointer;
  color: white;
  font-size: medium;
  font-weight: bold;
}
.EventItem__unfavoritebtn:after,
.EventItem__unfavoritebtn:before {
  content: " Remove From Favorites";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
}
.EventItem__unfavoritebtn:hover:after {
  top: -60%;
}
.EventItem__unfavoritebtn:before {
  font-family: "Ionicons";
  content: "\f252";
  font-size: 40px;
  top: 190%;
}
.EventItem__unfavoritebtn:hover:before {
  top: 50%;
}

.EventItem__unfavoritebtn:hover {
  width: 110px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 70px;
  /* transition-delay: 1s; */
}

.EventItem:hover .EventItem__unfavoritebtn {
  bottom: -20%;
  -webkit-transition: 1s;
  transition: 1s;
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}

.EventItem__unfavoritebtn:focus .button:before {
  font-family: "Ionicons";
  content: "\f141";
  font-size: 40px;
  top: 190%;
  background: blue;
}

.EventItem__goLogin {
  min-height: 60px;
  margin: 0px;
  padding: 0px;
  font-family: "helvetica Neue";
  position: relative;
}

.EventItem__goLoginbtn {
  width: 200px;
  min-height: 50px;
  background-image: radial-gradient(rgb(255, 255, 255), #009423);
  position: absolute;
  left: 50%;
  bottom: -20%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  border-radius: 7px;
  overflow: hidden;
  cursor: pointer;
  color: rgb(3, 30, 80);
  /* font-size: large; */
  font-weight: bold;
}
.EventItem__goLoginbtn:after,
.EventItem__goLoginbtn:before {
  content: "Login to Save Favorites";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
}
.EventItem__goLoginbtn:hover:after {
  top: -200%;
}
.EventItem__goLoginbtn:before {
  font-family: "Font Awesome 5 Free";
  content: "\f007";
  font-size: 40px;
  top: 190%;
  /* font-weight: 900; */
}
.EventItem__goLoginbtn:hover:before {
  top: 50%;
}

.EventItem__goLoginbtn:hover {
  width: 110px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border-radius: 70px;
  /* transition-delay: 1s; */
}

.EventItem:hover .EventItem__goLoginbtn {
  bottom: -20%;
  -webkit-transition: 1s;
  transition: 1s;
  -webkit-transition-delay: 1s;
          transition-delay: 1s;
}

.pagination-bar {
  text-align: center;
  margin-top: 20px;
}

.pagination-prev-next-button {
  /* button styles */
  padding: 10px 20px;
  /* color: white; */
  /* border: black 3px solid; */
  border-radius:20px ;
  background-color: #03a9f4;
}

.pagination-prev-next-button:hover{
  cursor: pointer;
}

.pagination-prev-next-button a{
  font-size: 17px;
  color: white;
  height: 100%;
  width: 100%;
}

.pagination-page-num {
  /* page number styles */
  /* font-weight: bold; */
  background-image: radial-gradient(#487eb0da, rgb(255, 255, 255));
  border-radius:20px ;
  padding: 10px 20px;
  margin: 10px;
}

.bold{
  font-weight: bold;
}

.SearchResults {
  font-family: sans-serif;
  margin: 10px auto;
  max-width: 800px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  border-radius: 7px;
  /* height: 100%; */
  align-content: stretch;
  justify-content: space-around;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  padding: 10px;
  background-color: rgb(241, 251, 253);
  /* background-image: radial-gradient(#487eb0da, rgb(255, 255, 255)); */
}
.SearchResults h2 {
  width: 95%;

  padding: 10px;
  text-align: center;
  margin: 5px auto;
  border-radius: 7px;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  background: rgba(250, 248, 241, 0.666);
}

.SearchResults ul {
  padding: 0px;
  /* display: inline-block;
    margin: 5px; */
  /* background-color: red;     */
  /* display: flex; */
  /* flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    border-radius: 7px; */
  /* height: 100%; */
  /* align-content: stretch; */
  /* justify-content: space-evenly; */
  width: 95%;
  margin: auto;
}

.weatherbar {
  font-family: sans-serif;
  margin: 10px auto;
  max-width: 800px;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  border-radius: 7px;
  /* height: 100%; */
  align-content: stretch;
  justify-content: space-around;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  padding: 10px;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#ffffffda), color-stop(#73a7d7da), to(#ffffffda));
  background-image: linear-gradient(to top, #ffffffda, #73a7d7da, #ffffffda);
}

.weatherbar {
  text-align: center;
}

.weatherbar h2 {
  width: 95%;

  padding: 10px;
  text-align: center;
  margin: 0 auto;
  border-radius: 7px;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  background: rgba(250, 248, 241, 0.666);
}

.weatherbar-header {
  text-align: center;
}

.weathericon {
  /* display: inline-block;
    margin: 5px; */
  /* background-color: red;     */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  border-radius: 7px;
  /* height: 100%; */
  /* align-content: stretch; */
  justify-content: space-evenly;
  /* width: 95%; */
  margin: auto;
  border-top: solid 4px #487eb0;
  border-bottom: solid 4px #487eb0;
  margin: 10px auto 5px auto;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}

.weathericon:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  /* border: 3px blue  ;
    border-style: outset; */
  background: -webkit-gradient(linear, left bottom, left top, from(white), to(#487eb0));
  background: linear-gradient(to top, white, #487eb0);
  border-radius: 7px;
}

.weathericon-time {
  /* text-align: center; */
  margin: auto 0px;
}

.weathericon-icon {
  margin: -20px -3px;
  min-width: 100px;
  min-height: 100px;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
}

@-webkit-keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}
.weathericon-temperature {
  text-align: center;
  margin: 0px;
  margin: auto 0px;
}

.high {
  color: darkred;
}

.low {
  color: darkblue;
}

@media screen and (max-width: 750px) {
  .EventsPage__form-section {
    width: 80%;
  }
  .EventsPage__checkbox__section input + label {
    flex-basis: 40%;
  }
}

.EventsPage {
  margin: 0 auto;
  padding: 70px 0;
  min-height: 80vh;
  /* max-width: 800px; */
  /* align-items: center; */
  width: 100%;
  /* margin: 0; */
  /* text-align: center; */
  /* height: 100vh; */
  /* padding-top: 70px; */
  /* box-sizing: border-box; */
  /* background: rgb(203, 220, 252) url("Desktop-1.png") repeat 0 0; */
  background: rgb(203, 220, 252) url(/static/media/Desktop-2.a402d7d1.png) repeat 0 0;

  /* animation: slide 30s linear infinite; */
}

@-webkit-keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -2108px 0;
  }
}

@keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -2108px 0;
  }
}

/*--------------------- SearchBar --------------- */

.EventsPage__Search {
  /* min-height: 125vh; */
  /* width:100%; */
  font-family: sans-serif;
  margin: 10px auto;
  border-radius: 7px;
  /* background: url("Desktop-1.png") no-repeat fixed center; */
  /* background-repeat: no-repeat;
    background-size:fixed; */
  /* animation: slide 20s  infinite; */
  max-width: 800px;
  background-image: radial-gradient(#487eb0da, rgb(255, 255, 255));
}

.EventsPage__form {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  border-radius: 7px;
  /* height: 100%; */
  align-content: stretch;
  justify-content: space-around;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

/* @media (min-width: 800px) {
    .SearchPage {
        min-height: 85vh;
    }
    .SearchPage {
        min-height: 85vh;
    }
} */

.EventsPage__form h4 {
  width: 80%;

  padding: 10px;
  text-align: center;
  margin: 0 auto;
  border-radius: 7px 7px 0 0;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  background: rgba(250, 248, 241, 0.666);
}

.EventsPage__form-section {
  margin: 10px auto;
  position: relative;
  height: 50px;
  border-radius: 0 0 7px 7px;
  z-index: 1;
  width: 30%;
  display: flex;
  /* flex-grow:1; */
  /* text-align: center; */
  /* background: blue; */
  /* overflow:hidden; */
}

.EventsPage__checkbox__section {
  /* position: relative; */
  /* padding :auto; */
  /* margin:0px !important;   */
  /* padding: 10px; */
  /* text-align: center; */
  /* margin:2px auto; */
  /* min-height: 170px; */
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  align-content: center;
  /* height: 100%; */
  /* align-content: stretch; */
  /* background: rgba(250, 248, 241, 0.666); */
  justify-content: space-evenly;
}

.EventsPage__checkbox__section input + label {
  margin: 0.2em;
  cursor: pointer;
  padding: 0.2em;
  flex-basis: 25%;
}

.EventsPage__checkbox__section input {
  display: none;
}

.EventsPage__checkbox__section input + label:before {
  content: "\2714";
  border: 0.1em solid #000;
  border-radius: 0.2em;
  display: inline-block;
  width: 1em;
  height: 1em;
  padding-left: 0.2em;
  padding-bottom: 0.3em;
  margin-right: 0.2em;
  vertical-align: bottom;
  color: transparent;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.EventsPage__checkbox__section input + label:active:before {
  -webkit-transform: scale(0);
          transform: scale(0);
}

.EventsPage__checkbox__section input:checked + label:before {
  background-color: rgb(97, 132, 248);
  border-color: rgb(97, 132, 248);
  color: #fff;
}

.EventsPage__checkbox__section input:disabled + label:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  border-color: #aaa;
}

EventsPage__checkbox__section input:checked:disabled + label:before {
  -webkit-transform: scale(1);
          transform: scale(1);
  border-color: rgb(157, 199, 250);
  background-color: rgb(157, 199, 250);
}

.input {
  width: 100%;
  height: 100%;
  color: grey;
  padding-top: 10px;
  font-size: 20px;
  border: none;
  text-align: center;
  outline: none;
  border-radius: 50px;
  text-align: center;
}
.label-name {
  position: absolute;
  bottom: 0px;
  left: 0%;
  width: 100%;
  height: 100%;
  pointer-events: none;
  border-bottom: 2px solid lightcoral;
  text-align: center;
  border-radius: 50px;
}

.label-name::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: -2px;
  height: 100%;
  width: 100%;
  border-bottom: 4px solid #487eb0;
  -webkit-transform: translateY(-3%);
          transform: translateY(-3%);
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  border-radius: 50px;
}

.content-name {
  position: absolute;
  bottom: 5px;
  left: 0px;
  right: 0px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.input:focus + .label-name .content-name,
.input:valid + .label-name .content-name {
  -webkit-transform: translateY(-150%);
          transform: translateY(-150%);
  font-size: 14px;
  color: #487eb0;
}

.input:focus + .label-name::after,
.input:valid + .label-name::after {
  -webkit-transform: translateX(0%);
          transform: translateX(0%);
}

.form-btn {
  width: 60%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  position: relative;
  height: 70px;
}

.EventsPage__searchForm__btn {
  /* position: absolute; */
  /* bottom:5px;
    left:0px;
    right:0px; */
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  width: 100%;
  /* height: 60px; */
  padding-top: 0;
  text-align: center;
  line-height: 30px;
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  text-decoration: none;
  font-family: sans-serif;
  box-sizing: border-box;
  background: -webkit-gradient(linear, left top, right top, from(#03a9f4), color-stop(#6e41f4), color-stop(#ffeb3b), to(#03a9f4));
  background: linear-gradient(90deg, #03a9f4, #6e41f4, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 30px;
  /* z-index: 1; */
}
.EventsPage__searchForm__btn:hover {
  cursor: pointer;
  -webkit-animation: animate 5s linear infinite;
          animation: animate 5s linear infinite;
}
@-webkit-keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}
@keyframes animate {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
}
.EventsPage__searchForm__btn:before {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background: -webkit-gradient(linear, left top, right top, from(#03a9f4), color-stop(#c983f5), color-stop(#ffeb3b), to(#03a9f4));
  background: linear-gradient(90deg, #03a9f4, #c983f5, #ffeb3b, #03a9f4);
  background-size: 400%;
  border-radius: 40px;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.EventsPage__searchForm__btn:hover:before {
  -webkit-filter: blur(20px);
          filter: blur(20px);
  opacity: 1;
  -webkit-animation: animate 5s linear infinite;
          animation: animate 5s linear infinite;
}

@media screen and (max-width: 750px) {
  .EventsPage__form-section {
    width: 80%;
  }
  .EventsPage__checkbox__section input + label {
    flex-basis: 40%;
  }
}

.fetch-error-bar {
    font-family: sans-serif;
    margin: 10px auto;
    max-width: 800px;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    border-radius: 7px;
    /* height: 100%; */
    align-content: stretch;
    justify-content: space-around;
    text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    padding: 10px;
    background-image: -webkit-gradient(linear, left bottom, left top, from(#ffffffda), color-stop(#f71109da), to(#ffffffda));
    background-image: linear-gradient(to top, #ffffffda, #f71109da, #ffffffda);
  }
  
  .fetch-error-bar {
    text-align: center;
  }
  
  .fetch-error-bar h2 {
    width: 95%;
    color: darkred;
    padding: 10px;
    text-align: center;
    margin: 0 auto;
    border-radius: 7px;
    text-shadow: 0px 0px 6px rgba(253, 253, 253, 0.2);
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    -webkit-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    background: rgba(250, 248, 241, 0.666);
  }
  
  .error-bar-header {
    text-align: center;
  }
  
.Loading{
  padding: 1px;
  text-align: center;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#ffffffda), color-stop(#73a7d7da), to(#ffffffda));
  background-image: linear-gradient(to top, #ffffffda, #73a7d7da, #ffffffda);
  margin: 10px auto;
  max-width: 800px;
  min-height: 300px;
  border-radius: 7px;
}

.Loading-container{

  margin: 30px auto;
  position: relative;
  width: 200px;
  height: 200px;
  /* border: black 1px solid;  */
  /* overflow: visible; */
}
.radar{
  overflow: visible;
}
.dish{
  /* overflow: visible; */
  /* position: absolute; */
  -webkit-transform-origin: 208px 250px;
          transform-origin: 208px 250px;
  -webkit-animation: dish 6s linear 0s infinite normal;
          animation: dish 6s linear 0s infinite normal;
}

@-webkit-keyframes dish{
  0%{-webkit-transform: rotate(-100deg);transform: rotate(-100deg);}
  50%{-webkit-transform: rotate(10deg);transform: rotate(10deg);}
  100%{-webkit-transform: rotate(-100deg);transform: rotate(-100deg);}
}

@keyframes dish{
  0%{-webkit-transform: rotate(-100deg);transform: rotate(-100deg);}
  50%{-webkit-transform: rotate(10deg);transform: rotate(10deg);}
  100%{-webkit-transform: rotate(-100deg);transform: rotate(-100deg);}
}
.SignInForm {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  display: flex;
  min-height: 80vh;
  background: rgb(196, 196, 196) url(/static/media/Desktop-1.05bea157.png) fixed center;
}
.box {
  width: 400px;
  margin: auto;
  padding: 20px;
  text-align: center;
  background: url(/static/media/bg.8b6771b8.png);
  overflow: hidden;
  border-radius: 7px;
}

.box h1 {
  margin-top: 0px;
  font-family: "Permanent Marker";
  color: #fff;
  font-size: 40px;
}

.box input {
  display: block;
  width: 100%;
  padding: 0 16px;
  height: 44px;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  border: none;
  font-family: "montserrat", sans-serif;
}

@-webkit-keyframes changeInputColor {
  0% {
    /* background: rgb(255, 255, 255); */
  }

  50% {
    width: 100%;
    background-color: #468ac9;
    /* background-image: radial-gradient(rgb(255, 255, 255),blue); */
  }

  100% {
    background: rgba(255, 255, 255, 0.694);
  }
}

@keyframes changeInputColor {
  0% {
    /* background: rgb(255, 255, 255); */
  }

  50% {
    width: 100%;
    background-color: #468ac9;
    /* background-image: radial-gradient(rgb(255, 255, 255),blue); */
  }

  100% {
    background: rgba(255, 255, 255, 0.694);
  }
}

.SignInForm input[name="username"] {
  -webkit-animation: changeInputColor 2s linear;
          animation: changeInputColor 2s linear;
}
.SignInForm input[name="password"] {
  -webkit-animation: changeInputColor 2s linear 1s;
          animation: changeInputColor 2s linear 1s;
}

.txtb {
  margin: 20px 0;
  background: rgba(255, 255, 255, 0.694);
  border-radius: 6px;
  font-size: large;
}
.signin-btn {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 70% !important;
  margin-bottom: 20px;
  background: #487eb0;
  color: #fff;
  font-size: large;
  border-radius: 44px;
  cursor: pointer;
  -webkit-transition: 0.8s;
  transition: 0.8s;
}
.signin-btn:hover {
  -webkit-transform: scale(0.96);
          transform: scale(0.96);
}
.SignInForm .haventAccYet {
  text-decoration: none;
  color: #fff;
  font-family: "montserrat", sans-serif;
  font-size: 14px;
  padding: 10px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  display: block;
  font-size: large;
}
.SignInForm .haventAccYet:hover {
  background: rgba(0, 0, 0, 0.3);
}

.LoadingLogin {
  text-align: center;
  background-image: -webkit-gradient(linear, left bottom, left top, from(#ffffffda), color-stop(#73a7d7da), to(#ffffffda));
  background-image: linear-gradient(to top, #ffffffda, #73a7d7da, #ffffffda);
  margin: 10px auto;
  /* max-width: 800px; */
  min-height: 80vh;
  border-radius: 7px;
}

.lock-container {
  margin: 25vh auto auto auto;
  padding: auto;
  position: relative;
  width: 200px;
  height: 200px;
  /* border: black 1px solid; */
}

.lock-svg {
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  /* animation: unlock 4s linear 0s infinite normal;  */
}

.lock-combo {
  -webkit-transform-origin: 50% 65.5%;
          transform-origin: 50% 65.5%;
  -webkit-animation: unlock 3s linear 0s infinite normal;
          animation: unlock 3s linear 0s infinite normal;
  /* transform-origin: 50% 50%; */
  /* fill: rgb(78, 78, 78) */
}

@-webkit-keyframes unlock {
  /* from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } */
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(35deg);
            transform: rotate(35deg);
  }
}

@keyframes unlock {
  /* from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  } */
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  50% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(35deg);
            transform: rotate(35deg);
  }
}

.SignUpPage {
  min-height: 100vh;
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  background: rgb(196, 196, 196) url(/static/media/Desktop-1.05bea157.png) fixed center;
}
@media (min-width: 800px) {
  .SignUpPage {
    min-height: 85vh;
  }
}
.signup-form {
  width: 400px;
  margin: auto;
  padding: 20px;
  text-align: center;
  background: url(/static/media/bg.8b6771b8.png);
  overflow: hidden;
  border-radius: 7px;
}
.signup-form h1 {
  margin-top: 0px;
  font-family: "Permanent Marker";
  color: #fff;
  font-size: 40px;
}
.signup-form input {
  display: block;
  width: 100%;
  padding: 0 16px;
  height: 44px;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  border: none;
  font-family: "montserrat", sans-serif;
}

@-webkit-keyframes changeInputColor {
  0% {
    /* background: rgb(255, 255, 255); */
  }

  50% {
    width: 100%;
    background-color: #468ac9;
    /* background-image: radial-gradient(rgb(255, 255, 255),blue); */
  }

  100% {
    background: rgba(255, 255, 255, 0.694);
  }
}

@keyframes changeInputColor {
  0% {
    /* background: rgb(255, 255, 255); */
  }

  50% {
    width: 100%;
    background-color: #468ac9;
    /* background-image: radial-gradient(rgb(255, 255, 255),blue); */
  }

  100% {
    background: rgba(255, 255, 255, 0.694);
  }
}

.signup-form input[name="username"] {
  -webkit-animation: changeInputColor 2s linear;
          animation: changeInputColor 2s linear;
}
.signup-form input[name="nickname"] {
  -webkit-animation: changeInputColor 2s linear 1s;
          animation: changeInputColor 2s linear 1s;
}
.signup-form input[name="email"] {
  -webkit-animation: changeInputColor 2s linear 2s;
          animation: changeInputColor 2s linear 2s;
}
.signup-form input[name="password"] {
  -webkit-animation: changeInputColor 2s linear 3s;
          animation: changeInputColor 2s linear 3s;
}

.txtb {
  margin: 20px 0;
  background: rgba(255, 255, 255, 0.694);
  border-radius: 6px;
  font-size: large;
}
.signup-btn {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 70% !important;
  margin-bottom: 20px;
  background: #487eb0;
  color: #fff;
  font-size: large;
  border-radius: 44px;
  cursor: pointer;
  -webkit-transition: 0.8s;
  transition: 0.8s;
}
.signup-btn:hover {
  -webkit-transform: scale(0.96);
          transform: scale(0.96);
}
.signup-form .alreadyHaveAcc {
  text-decoration: none;
  color: #fff;
  font-family: "montserrat", sans-serif;
  font-size: 14px;
  padding: 10px;
  -webkit-transition: 0.8s;
  transition: 0.8s;
  display: block;
  font-size: large;
}
.signup-form .alreadyHaveAcc:hover {
  background: rgba(0, 0, 0, 0.3);
}

.NotFoundPage {
  min-height: 80vh;
  margin: 0;
  padding: 0;
  font-family: "montserrat", sans-serif;
  background-image: linear-gradient(130deg, white, #fcb6b5a4, white);
  /* background-image: linear-gradient(125deg,#6a89cc,#e9b594); */
}

.container {
  width: 100%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  text-align: center;
  color: #343434;
}

.container h1 {
  font-size: 150px;
  margin: 0;
  font-weight: 900;
  letter-spacing: 20px;
  background: url(/static/media/bg.c1194249.jpg) center no-repeat;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  /* animation: anim 4s ease infinite; */
  /* animation: fill 0.5s ease forwards 3s; */
}

@-webkit-keyframes fill {
  from {
    fill: transparent;
  }
  to {
    fill: rgb(20, 18, 18);
  }
}

@keyframes fill {
  from {
    fill: transparent;
  }
  to {
    fill: rgb(20, 18, 18);
  }
}

@-webkit-keyframes anim {
  0% {
    font-size: 150px;
  }
  50% {
    font-size: 300px;
  }
  100% {
    font-size: 150px;
  }
}

@keyframes anim {
  0% {
    font-size: 150px;
  }
  50% {
    font-size: 300px;
  }
  100% {
    font-size: 150px;
  }
}

.MyEventsPage__container {
  margin: 0 auto;
  padding: 70px 0;
  min-height: 80vh;
  /* max-width: 800px; */
  /* align-items: center; */
  width: 100%;
  /* margin: 0; */
  /* text-align: center; */
  /* height: 100vh; */
  /* padding-top: 70px; */
  /* box-sizing: border-box; */
  /* background: rgb(203, 220, 252) url("Desktop-1.png") repeat 0 0; */
  background: rgb(203, 220, 252) url(/static/media/Desktop-2.a402d7d1.png) repeat 0 0;
  /* animation: slide 30s linear infinite; */
}

@-webkit-keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -2108px 0;
  }
}

@keyframes slide {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -2108px 0;
  }
}

.MyEventsPage__subContainer {
  max-width: 800px;
  font-family: sans-serif;
  margin: 10px auto;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  border-radius: 7px;
  /* height: 100%; */
  align-content: stretch;
  justify-content: space-around;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  padding: 10px;
  background-color: rgb(241, 251, 253);
}

.MyEventsPage {
  max-width: 100%;
  font-family: sans-serif;
  margin: 10px;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  border-radius: 7px;
  /* height: 100%; */
  align-content: stretch;
  justify-content: space-around;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  padding: 10px;
  background-color: rgb(241, 251, 253);
}
.MyEventsPage h2 {
  width: 95%;

  padding: 10px;
  text-align: center;
  margin: 5px auto;
  border-radius: 7px;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  background: rgba(250, 248, 241, 0.666);
}

.MyEventsPage ul {
  padding: 0px;
  /* display: inline-block;
    margin: 5px; */
  /* background-color: red;     */
  /* display: flex; */
  /* flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    border-radius: 7px; */
  /* height: 100%; */
  /* align-content: stretch; */
  /* justify-content: space-evenly; */
  width: 95%;
  margin: auto;
}
.MyEventsPage .h3 {
  width: 95%;
  padding: 10px;
  text-align: center;
  margin: 5px auto;
  border-radius: 7px;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  background: rgba(250, 248, 241, 0.666);
}

.weekend__button__component {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 10vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* background: #000; */
}

.weekend__button {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  background: rgb(0, 64, 143);
  cursor: pointer;
  position: relative;
  z-index: 0;
  font-size: large;
  border-radius: 10px;
}

.weekend__button:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  -webkit-filter: blur(5px);
          filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  -webkit-animation: glowing 20s linear infinite;
          animation: glowing 20s linear infinite;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.weekend__button:hover {
  color: #000;
}

.weekend__button:hover:after {
  background: transparent;
}

.weekend__button:before {
  opacity: 1;
}

.weekend__button:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(65, 0, 97, 0.906);
  left: 0;
  top: 0;
  border-radius: 10px;
}

@-webkit-keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.ErrorPage {
  min-height: 80vh;
  min-width: 100%;
  margin: 0;
  padding: 0;
  font-family: "montserrat", sans-serif;
  background-image: linear-gradient(130deg, white, #fcb6b5a4, white);
}

.error-container {
  width: 100%;
  padding: 20px;
  text-align: center;
  color: #343434;
}

h1.error-page-text {
  font-size: 120px;
  margin: 0;
  margin-top: 100px;
  font-weight: 900;
  background: 
    -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,0.4)), to(rgba(0,0,0,0.4))),
    url(/static/media/bg.c1194249.jpg) center;
  background: 
    linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),
    url(/static/media/bg.c1194249.jpg) center;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

h2.error-page-text {
  font-size: 55px;
  margin: 20px;
  font-weight: 700;
}

p.error-page-text {
  font-size: 24px;
  margin: 0;
  font-weight: 400;
}

@media only screen and  (max-width: 800px) {
  h1.error-page-text {
    margin-top: 40px;
  }
}

@media only screen and  (max-width: 500px) {
  h1.error-page-text {
    font-size: 80px;
  }
  
  h2.error-page-text {
    font-size: 40px;
  }
  
  p.error-page-text {
    font-size: 18px;
  }
}
