.weatherbar {
  font-family: sans-serif;
  margin: 10px auto;
  max-width: 800px;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  border-radius: 7px;
  /* height: 100%; */
  align-content: stretch;
  justify-content: space-around;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
  padding: 10px;
  background-image: linear-gradient(to top, #ffffffda, #73a7d7da, #ffffffda);
}

.weatherbar {
  text-align: center;
}

.weatherbar h2 {
  width: 95%;

  padding: 10px;
  text-align: center;
  margin: 0 auto;
  border-radius: 7px;
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
  background: rgba(250, 248, 241, 0.666);
}

.weatherbar-header {
  text-align: center;
}

.weathericon {
  /* display: inline-block;
    margin: 5px; */
  /* background-color: red;     */
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  border-radius: 7px;
  /* height: 100%; */
  /* align-content: stretch; */
  justify-content: space-evenly;
  /* width: 95%; */
  margin: auto;
  border-top: solid 4px #487eb0;
  border-bottom: solid 4px #487eb0;
  margin: 10px auto 5px auto;
  animation-duration: 6s;
  animation-name: fadeIn;
}

.weathericon:hover {
  transform: scale(1.1);
  /* border: 3px blue  ;
    border-style: outset; */
  background: linear-gradient(to top, white, #487eb0);
  border-radius: 7px;
}

.weathericon-time {
  /* text-align: center; */
  margin: auto 0px;
}

.weathericon-icon {
  margin: -20px -3px;
  min-width: 100px;
  min-height: 100px;
  animation-duration: 6s;
  animation-name: fadeIn;
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity: 1;}
}
.weathericon-temperature {
  text-align: center;
  margin: 0px;
  margin: auto 0px;
}

.high {
  color: darkred;
}

.low {
  color: darkblue;
}

@media screen and (max-width: 750px) {
  .EventsPage__form-section {
    width: 80%;
  }
  .EventsPage__checkbox__section input + label {
    flex-basis: 40%;
  }
}
