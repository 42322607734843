.SignInForm {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  display: flex;
  min-height: 80vh;
  background: rgb(196, 196, 196) url("Desktop-1.png") fixed center;
}
.box {
  width: 400px;
  margin: auto;
  padding: 20px;
  text-align: center;
  background: url(bg.png);
  overflow: hidden;
  border-radius: 7px;
}

.box h1 {
  margin-top: 0px;
  font-family: "Permanent Marker";
  color: #fff;
  font-size: 40px;
}

.box input {
  display: block;
  width: 100%;
  padding: 0 16px;
  height: 44px;
  text-align: center;
  box-sizing: border-box;
  outline: none;
  border: none;
  font-family: "montserrat", sans-serif;
}

@keyframes changeInputColor {
  0% {
    /* background: rgb(255, 255, 255); */
  }

  50% {
    width: 100%;
    background-color: #468ac9;
    /* background-image: radial-gradient(rgb(255, 255, 255),blue); */
  }

  100% {
    background: rgba(255, 255, 255, 0.694);
  }
}

.SignInForm input[name="username"] {
  animation: changeInputColor 2s linear;
}
.SignInForm input[name="password"] {
  animation: changeInputColor 2s linear 1s;
}

.txtb {
  margin: 20px 0;
  background: rgba(255, 255, 255, 0.694);
  border-radius: 6px;
  font-size: large;
}
.signin-btn {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  width: 70% !important;
  margin-bottom: 20px;
  background: #487eb0;
  color: #fff;
  font-size: large;
  border-radius: 44px;
  cursor: pointer;
  transition: 0.8s;
}
.signin-btn:hover {
  transform: scale(0.96);
}
.SignInForm .haventAccYet {
  text-decoration: none;
  color: #fff;
  font-family: "montserrat", sans-serif;
  font-size: 14px;
  padding: 10px;
  transition: 0.8s;
  display: block;
  font-size: large;
}
.SignInForm .haventAccYet:hover {
  background: rgba(0, 0, 0, 0.3);
}
