.app-header {
  display: flex;
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  top: 0;
  height: 10vh;
  justify-content: space-between;
  padding: 10px 10px;
  /* border-bottom: 5px solid ; */
  /* border-color: rgb(54, 12, 91); */
  text-transform: uppercase;
  letter-spacing: -0.025em;
  align-items: center;
  background: linear-gradient(
    to bottom,
    #487eb0,
    rgb(241, 251, 253),
    rgb(241, 251, 253),
    rgb(241, 251, 253)
  );
  /* margin-bottom: 15px; */
  /* color:white; */
  /* color:purple,blue,skyblue,green,yellow,orange,red; */
  /* background: linear-gradient(to right,purple,blue,skyblue,green,yellow,orange,red); */
  z-index: 1111;
  /* max-width: 1450px; */
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .app-header {
    padding: 10px 25px;
  }
}

.login-controls,
.logged-in-controls {
  flex: 40%;
  text-align: right;
}

.btn {
  /* border: 2px solid #7b8083; */
  background: none;
  /* padding: 10px 20px; */
  font-size: 20px;
  font-family: "montserrat";
  cursor: pointer;
  margin: 0 3px;
  transition: 0.8s;
  position: relative;
  overflow: hidden;
}
.btn1,
.btn2 {
  color: #2b3033;
}
.btn3,
.btn4 {
  color: #ffffff;
}
.btn1:hover,
.btn2:hover {
  color: #fff;
}
.btn3:hover,
.btn4:hover {
  color: #212121;
}
.btn::before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0%;
  background: rgba(84, 143, 245, 0.673);
  z-index: -1;
  transition: 0.8s;
}
.btn1::before,
.btn3::before {
  top: 0;
  border-radius: 4px;
}
.btn2::before,
.btn4::before {
  bottom: 0;
  border-radius: 4px;
}
.btn3::before,
.btn4::before {
  height: 100%;
}
.btn1:hover::before,
.btn2:hover::before {
  height: 100%;
}
.btn3:hover::before,
.btn4:hover::before {
  height: 0%;
}

.header__nav {
  /* height: 100px; */
  /* background: #34495e; */
  /* padding: 0 20px; */
  color: #fff;
  flex: 40%;
  text-align: right;
}
.logo {
  /* line-height: 30px; */
  float: left;
  text-transform: uppercase;
  /* width:50px; */
}
.fa-ellipsis-h {
  color: grey;
}
.menu {
  float: right;
  line-height: 40px;
  width: 80%;
  /* color:black; */
  /* border: #212121 solid ; */
  text-align: right;
  height: 100%;
}
.menu a {
  /* color: black; */
  /* text-transform: uppercase; */
  text-decoration: none;
  padding: 0 3px;
  transition: 0.4s;
}
.show-menu-btn,
.hide-menu-btn {
  transition: 0.4s;
  font-size: 30px;
  cursor: pointer;
  display: none;
}
.show-menu-btn {
  float: right;
}
.show-menu-btn i {
  line-height: 80px;
}
/* .menu a:hover,
.show-menu-btn:hover,
.hide-menu-btn:hover{
  color: #3498db;
} */

#chk {
  position: absolute;
  visibility: hidden;
  z-index: -1111;
}

@media screen and (max-width: 750px) {
  .show-menu-btn,
  .hide-menu-btn {
    display: block;
  }
  .menu {
    /* display: flex; */
    position: fixed;
    width: 100%;
    /* height: 100vh; */
    background: #487eb0;
    left: -100%;
    top: 0;
    text-align: center;
    padding: 80px 0;
    line-height: normal;
    transition: 0.7s;
    z-index: 1111;
    /* display: none; */
    /* line-height: 100px; */
    margin-block-start: 0;
  }
  .menu a {
    display: block;
    padding: 20px;
  }
  .hide-menu-btn {
    position: absolute;
    top: 40px;
    right: 40px;
  }
  #chk:checked ~ .menu {
    transition: 1.5s;
    left: 0;
    /* animation: menuBar 2s ease; */
  }

  @keyframes menuBar {
    0% {
      left: 25;
    }

    /* 50% {
      left: -10;
    }
    60% {
      left: 5;
    } */
    100% {
      left: 0;
    }
  }
  /* .menu a:active {
    color: blue;
  } */

  /* .menu a:active #chk {
    checked: attr(false);
  } */
}
