* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {

  text-decoration: none;

}

ul {
  list-style-type: none;
margin:0px;
padding:0px;
}

li {

  list-style: none;

}
.green{
  color: green;
}

.red{
  color: red;
}

.bold{
  font-weight: bold;
}
.alert{
  color:red;
  margin: 10px auto;
}