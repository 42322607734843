.ErrorPage {
  min-height: 80vh;
  min-width: 100%;
  margin: 0;
  padding: 0;
  font-family: "montserrat", sans-serif;
  background-image: linear-gradient(130deg, white, #fcb6b5a4, white);
}

.error-container {
  width: 100%;
  padding: 20px;
  text-align: center;
  color: #343434;
}

h1.error-page-text {
  font-size: 120px;
  margin: 0;
  margin-top: 100px;
  font-weight: 900;
  background: 
    linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)),
    url(../../routes/NotFoundPage/bg.jpg) center;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

h2.error-page-text {
  font-size: 55px;
  margin: 20px;
  font-weight: 700;
}

p.error-page-text {
  font-size: 24px;
  margin: 0;
  font-weight: 400;
}

@media only screen and  (max-width: 800px) {
  h1.error-page-text {
    margin-top: 40px;
  }
}

@media only screen and  (max-width: 500px) {
  h1.error-page-text {
    font-size: 80px;
  }
  
  h2.error-page-text {
    font-size: 40px;
  }
  
  p.error-page-text {
    font-size: 18px;
  }
}